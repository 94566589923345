import React from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';
import Layout, { LayoutOptions, HeroSectionColors } from '../../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../../localization/translations';
import PageMenu from '../../../../components/PageMenu/PageMenu';
import residentialMortgagesMenu from '../../../../data/page-menus/residential-mortgages';
import { IconTypes } from '../../../../components/Icon/Icon';
import ButtonLink from '../../../../components/ButtonLink/ButtonLink';
import { useLocaleContext } from '../../../../context/Locale';
import { LocaleTag } from '../../../../../config/locales';

import './style.scss';

type Props = PageProps & WithTranslation;

const PurchasingHome: React.FC<Props> = (props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t(`purchasingHome.seo.title`),
    },
    page: {
      title: t(`purchasingHome.heading`),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.SKY_BLUE,
        heroImage: {
          fileName: 'hero-image-12.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const mortgageProScanFindBrokerLink =
    activeLocaleTag === LocaleTag.EN
      ? 'https://mortgageproscan.ca/findabroker'
      : 'https://mortgageproscan.ca/fr/trouvezvotrecourtier';

  return (
    <Layout options={layoutOptions} className="PurchasingHome">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <div className="PurchasingHome__generic-gap">
              <p>
                <Trans i18nKey="purchasingHome.body" t={t} />
              </p>
              <ButtonLink
                iconOptions={{
                  icon: IconTypes.ARROW_NEXT,
                }}
                linkOptions={{
                  isExternalLink: true,
                }}
                to={mortgageProScanFindBrokerLink}
                styleOptions={{ isInline: true }}
              >
                {t('purchasingHome.buttonLabel')}
              </ButtonLink>
            </div>
          </section>
          <aside className="column column-33">
            <PageMenu
              menu={residentialMortgagesMenu}
              menuTitle={t(`${LocaleNameSpaceKeys.COMMON}:pageMenuTitles.residentialMortgages`)}
            />
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.CUSTOMER_CENTRE)(PurchasingHome);
